export const HowlLogoVideoBackground = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full z-negative flex">
      {/* Desktop Video */}
      <video
        autoPlay
        muted
        loop
        playsInline
        className="hidden sm:block w-full h-full object-contain"
        style={{ objectPosition: 'center center' }}
      >
        <source src="/videos/howl-background-large.mp4" type="video/mp4" />
      </video>

      {/* Mobile Video */}
      <video
        autoPlay
        muted
        loop
        playsInline
        width={'100%'}
        height={'auto'}
        className="block sm:hidden"
        style={{
          width: '100%',
          height: 'auto',
        }}
      >
        <source src="/videos/howl-background-small.mp4" type="video/mp4" />
      </video>
    </div>
  );
};
