'use client';
import { SignUp } from '@clerk/nextjs';
import { HowlLogoVideoBackground } from '../../sign-in/_components/HowlLogoVideoBackground';
import { useSearchParams } from 'next/navigation';

export const PartnerSignUpComponent = () => {
  const searchParams = useSearchParams();
  const redirectUrl = searchParams.get('redirect_url');

  return (
    <main className="flex justify-center h-full w-full">
      <div className="fixed w-full h-full mx-auto z-10">
        <SignUp
          appearance={{
            elements: {
              logoBox: 'hidden',
              card: '!rounded-none !shadow-none px-8',
              cardBox:
                '!bg-white !rounded-3xl sm:w-[400px] !shadow-[0px_8px_16px_0px_rgba(0,0,0,0.10)] border border-subtle',
              headerTitle: 'text-headline-large text-3xl text-primary',
              headerSubtitle: 'text-headline-large text-text-tertiary',
              formButtonPrimary:
                '!bg-interaction-primary h-14 text-sm normal-case',
              socialButtons: 'mx-auto gap-4 flex flex-row',
              providerIcon: 'h-8 w-8',
              button__facebook:
                'h-16 w-16 rounded-medium !shadow-md !border border-dark-opacity-10',
              button__google:
                'h-16 w-16 rounded-medium !shadow-md !border border-dark-opacity-10',
              button__tiktok:
                'h-16 w-16 rounded-medium !shadow-md !border border-dark-opacity-10',
              dividerText: 'text-base text-tertiary',
              dividerRow: 'uppercase font-mono !text-primary !my-0',
              buttonArrowIcon: 'hidden',
              formFieldInput: 'h-12 py-4 !border-primary',
              rootBox:
                'w-full h-full flex flex-col items-center justify-center z-20 !my-0 !shadow-none',
              footer: 'm-2 rounded-2xl !bg-primary !py-0',
              footerAction: '!py-4',
              footerActionText: 'text-sm font-sans font-medium',
              footerActionLink: 'text-sm underline underline-offset-4',
            },
          }}
          forceRedirectUrl={redirectUrl}
          signInForceRedirectUrl={redirectUrl}
          signInUrl={`/sign-in?redirect_url=${
            redirectUrl ? encodeURIComponent(redirectUrl) : ''
          }`}
        />
      </div>

      <HowlLogoVideoBackground />
    </main>
  );
};
